const NavItems = [
   {
      title: 'Projekty',
      path: '/projekty',
      cName: 'nav-item'
   },
   {
      title: 'Služby',
      path: '/sluzby',
      cName: 'nav-item'
   },
   {
      title: 'Kontakt',
      path: '/kontakt',
      cName: 'nav-item'
   }
]

export default NavItems